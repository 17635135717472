import PERMISSION from "./permission-codes"

const PERMISSIONS = {
  [PERMISSION.READ_MATERIAL]: {
    endpoint_dependencies: [
      "allMaterialImpl",
      "getMaterialImpl",
      "allInventoryTypeImpl",
    ],
    name: "Read material",
    description: "Access all material items and single material item detail",
  },
  [PERMISSION.READ_MATERIAL_PAGES]: {
    endpoint_dependencies: [
      "allMaterialImpl",
      "getMaterialImpl",
      "allInventoryTypeImpl",
      "readMaterialPagesImpl",
    ],
    name: "Read material pages",
  },
  [PERMISSION.WRITE_MATERIAL]: {
    endpoint_dependencies: [
      "saveMaterialImpl",
      "updateMaterialImpl",
      "deleteMaterialImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_MATERIAL,
      PERMISSION.READ_MATERIAL_PAGES,
    ],
    name: "Write, modify, and delete material",
  },
  [PERMISSION.READ_PAYMENT_METHOD]: {
    endpoint_dependencies: ["allPaymentMethodImpl", "getPaymentMethodImpl"],
    name: "Read payment method",
    description: "Access all payment method items and single item detail",
  },
  [PERMISSION.READ_PAYMENT_METHOD_PAGES]: {
    endpoint_dependencies: [
      "allPaymentMethodImpl",
      "getPaymentMethodImpl",
      "readPaymentMethodPagesImpl",
    ],
    name: "Read payment method pages",
    permission_dependencies: [PERMISSION.READ_PAYMENT_METHOD],
  },
  [PERMISSION.WRITE_PAYMENT_METHOD]: {
    endpoint_dependencies: [
      "savePaymentMethodImpl",
      "updatePaymentMethodImpl",
      "deletePaymentMethodImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_PAYMENT_METHOD,
      PERMISSION.READ_PAYMENT_METHOD_PAGES,
    ],
    name: "Write, modify, and delete payment method",
  },
  [PERMISSION.READ_CURRENCY]: {
    endpoint_dependencies: ["allCurrencyImpl", "getCurrencyImpl"],
    name: "Read currency",
    description: "Access all currency items and single customer detail",
  },
  [PERMISSION.READ_CURRENCY_PAGES]: {
    endpoint_dependencies: [
      "allCurrencyImpl",
      "getCurrencyImpl",
      "readCurrencyPagesImpl",
    ],
    permission_dependencies: [PERMISSION.READ_CUSTOMER],
    name: "Read currency pages",
  },
  [PERMISSION.WRITE_CURRENCY]: {
    endpoint_dependencies: [
      "saveCurrencyImpl",
      "updateCurrencyImpl",
      "deleteCurrencyImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_CURRENCY,
      PERMISSION.READ_CUSTOMER_PAGES,
    ],
    name: "Write and modify currency",
  },
  [PERMISSION.READ_CUSTOMER]: {
    endpoint_dependencies: ["allCustomerImpl", "getCustomerImpl"],
    permission_dependencies: [
      PERMISSION.READ_CURRENCY,
      PERMISSION.READ_PAYMENT_METHOD,
    ],
    name: "Read customer",
    description: "Access all customer items and single customer detail",
  },
  [PERMISSION.READ_CUSTOMER_PAGES]: {
    endpoint_dependencies: [
      "allCustomerImpl",
      "getCustomerImpl",
      "readCustomerPagesImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_CURRENCY,
      PERMISSION.READ_CUSTOMER,
      PERMISSION.READ_PAYMENT_METHOD,
    ],
    name: "Read customer pages",
  },
  [PERMISSION.WRITE_CUSTOMER]: {
    endpoint_dependencies: ["saveCustomerImpl", "updateCustomerImpl"],
    permission_dependencies: [
      PERMISSION.READ_CUSTOMER,
      PERMISSION.READ_CURRENCY,
      PERMISSION.READ_PAYMENT_METHOD,
      PERMISSION.READ_CUSTOMER_PAGES,
    ],
    name: "Write and modify customer",
  },
  [PERMISSION.READ_COMPANY_INFORMATION]: {
    endpoint_dependencies: ["getCompanyInformationImpl"],
    name: "Read company information",
    description:
      "Access all company information items and single company information detail",
  },
  [PERMISSION.READ_COMPANY_INFORMATION_PAGES]: {
    endpoint_dependencies: [
      "getCompanyInformationImpl",
      "readCompanyInformationPagesImpl",
    ],
    name: "Read company information pages",
    permission_dependencies: [PERMISSION.READ_COMPANY_INFORMATION],
  },
  [PERMISSION.WRITE_COMPANY_INFORMATION]: {
    endpoint_dependencies: ["updateCompanyInformationImpl"],
    permission_dependencies: [
      PERMISSION.READ_COMPANY_INFORMATION,
      PERMISSION.READ_COMPANY_INFORMATION_PAGES,
    ],
    name: "Write and modify company information",
  },
  [PERMISSION.READ_PROCESS]: {
    endpoint_dependencies: ["allProcessImpl", "getProcessImpl"],
    name: "Read process",
    description: "Access all process items and single process detail",
  },
  [PERMISSION.READ_PROCESS_PAGES]: {
    endpoint_dependencies: [
      "allProcessImpl",
      "getProcessImpl",
      "readProcessPagesImpl",
    ],
    name: "Read process pages",
  },
  [PERMISSION.WRITE_PROCESS]: {
    endpoint_dependencies: [
      "saveProcessImpl",
      "updateProcessImpl",
      "deleteProcessImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_PROCESS,
      PERMISSION.READ_PROCESS_PAGES,
    ],
    name: "Write, modify, and delete process items",
  },
  [PERMISSION.READ_MACHINE]: {
    endpoint_dependencies: ["allMachineImpl", "getMachineImpl"],
    name: "Read machine",
    description: "Access all machine items and single machine detail",
  },
  [PERMISSION.READ_MACHINE_PAGES]: {
    endpoint_dependencies: [
      "allMachineImpl",
      "getMachineImpl",
      "readMachinePagesImpl",
    ],
    name: "Read machine pages",
  },
  [PERMISSION.WRITE_MACHINE]: {
    endpoint_dependencies: [
      "saveMachineImpl",
      "updateMachineImpl",
      "deleteMachineImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_MACHINE,
      PERMISSION.READ_MACHINE_PAGES,
    ],
    name: "Write, modify, and delete machine items",
  },
  [PERMISSION.READ_PURCHASE_ORDER]: {
    endpoint_dependencies: [
      "allPurchaseOrderImpl",
      "getPurchaseOrderImpl",
      "getGoodReceiptWithPo",
    ],
    name: "Read purchase orders",
    permission_dependencies: [PERMISSION.READ_BILL_OF_MATERIAL],
  },
  [PERMISSION.READ_PURCHASE_ORDER_PAGES]: {
    endpoint_dependencies: ["readPurchaseOrderPagesImpl"],
    permission_dependencies: [PERMISSION.READ_PURCHASE_ORDER],
    name: "Read purchase order pages",
  },
  [PERMISSION.WRITE_AMOUNT_PURCHASE_ORDER]: {
    endpoint_dependencies: ["updatePurchaseOrderAmountImpl"],
    permission_dependencies: [PERMISSION.READ_PURCHASE_ORDER],
    name: "Update purchase order amount",
  },
  [PERMISSION.WRITE_PURCHASE_ORDER]: {
    endpoint_dependencies: [
      "savePurchaseOrderImpl",
      "deletePurchaseOrderImpl",
      "updatePurchaseOrderImpl",
      "revisePurchaseOrderImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_PURCHASE_ORDER,
      PERMISSION.READ_PURCHASE_ORDER_PAGES,
    ],
    name: "Write and modify purchase order items",
  },
  [PERMISSION.WRITE_STATUS_PURCHASE_ORDER]: {
    endpoint_dependencies: ["updateStatusPurchaseOrderImpl"],
    permission_dependencies: [PERMISSION.READ_PURCHASE_ORDER],
    name: "Update status of purchase order",
  },
  [PERMISSION.SET_RECEIVED_PURCHASE_ORDER]: {
    endpoint_dependencies: ["setReceivedPurchaseOrder"],
    permission_dependencies: [PERMISSION.WRITE_STATUS_PURCHASE_ORDER],
    name: "Set purchase order status to received",
  },
  [PERMISSION.READ_VENDOR]: {
    endpoint_dependencies: ["allVendorImpl", "getVendorImpl"],
    permission_dependencies: [
      PERMISSION.READ_CURRENCY,
      PERMISSION.READ_PAYMENT_METHOD,
      PERMISSION.READ_MATERIAL,
    ],
    name: "Read vendor",
    description: "Access all vendor items and single customer detail",
  },
  [PERMISSION.READ_VENDOR_PAGES]: {
    endpoint_dependencies: ["readVendorPagesImpl"],
    permission_dependencies: [PERMISSION.READ_VENDOR],
    name: "Read vendor page",
  },
  [PERMISSION.WRITE_VENDOR]: {
    endpoint_dependencies: [
      "saveVendorImpl",
      "updateVendorImpl",
      "deleteVendorImpl",
      "saveMaterialToVendorImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_VENDOR,
      PERMISSION.READ_CURRENCY,
      PERMISSION.READ_PAYMENT_METHOD,
      PERMISSION.READ_VENDOR_PAGES,
    ],
    name: "Write and modify vendor",
  },
  [PERMISSION.READ_ROLE_PAGES]: {
    endpoint_dependencies: ["readRolePagesImpl"],
    name: "Read role pages",
    permission_dependencies: [PERMISSION.READ_ROLES],
  },
  [PERMISSION.READ_ROLES]: {
    endpoint_dependencies: [
      "allRoleImpl",
      "getRoleImpl",
      "allRoleEndpointsImpl",
      "allUserRolesImpl",
      "allEndpointImpl",
    ],
    name: "Read roles and permissions",
    permission_dependencies: [PERMISSION.READ_USER],
  },
  [PERMISSION.WRITE_ROLES]: {
    endpoint_dependencies: [
      "saveRoleImpl",
      "updateRoleImpl",
      "deleteRoleImpl",
      "saveRoleEndpointImpl",
      "deleteRoleEndpointImpl",
      "saveUserRoleImpl",
      "deleteUserRoleImpl",
      "updateEndpointImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_ROLES,
      PERMISSION.READ_ROLE_PAGES,
    ],
    name: "Write, modify, and delete role data",
  },
  [PERMISSION.READ_ESTIMATE_FOR_QUOTATION]: {
    endpoint_dependencies: [
      "allEstimateQuotationImpl",
      "getEstimateQuotationImpl",
      "allEstimateProcessImpl",
      "getEstimateProcessImpl",
      "allEstimateMaterialImpl",
      "getEstimateMaterialImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_MATERIAL,
      PERMISSION.READ_PROCESS,
      PERMISSION.READ_CURRENCY,
      PERMISSION.READ_CUSTOMER,
    ],
    name: "Read estimates for quotation",
  },

  [PERMISSION.READ_ESTIMATE_FOR_QUOTATION_PAGES]: {
    endpoint_dependencies: [
      "allEstimateQuotationImpl",
      "getEstimateQuotationImpl",
      "allEstimateProcessImpl",
      "getEstimateProcessImpl",
      "allEstimateMaterialImpl",
      "getEstimateMaterialImpl",
      "readEstimateQuotationPagesImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_MATERIAL,
      PERMISSION.READ_PROCESS,
      PERMISSION.READ_CURRENCY,
      PERMISSION.READ_CUSTOMER,
      PERMISSION.READ_USER,
    ],
    name: "Read estimates for quotation pages",
  },
  [PERMISSION.WRITE_ESTIMATE_FOR_QUOTATION]: {
    endpoint_dependencies: [
      "saveEstimateQuotationImpl",
      "updateEstimateQuotationImpl",
      "deleteEstimateMaterialImpl",
      "deleteEstimateProcessImpl",
      "updatePostedEstimateQuotationImpl",
      "reviseEstimateQuotationImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_ESTIMATE_FOR_QUOTATION,
      PERMISSION.READ_ESTIMATE_FOR_QUOTATION_PAGES,
      PERMISSION.READ_CUSTOMER,
    ],
    name: "Write and modify estimates for quotation",
  },
  [PERMISSION.WRITE_START_DATE_PROJECT_ESTIMATE_FOR_QUOTATION]: {
    name: "Write and modify start date project on estimate for quotation",
    endpoint_dependencies: ["updateStartDateProjectEstimateQuotationImpl"],
    permission_dependencies: [PERMISSION.READ_ESTIMATE_FOR_QUOTATION],
  },
  [PERMISSION.REVERT_CANCELED_ESTIMATE_FOR_QUOTATION]: {
    name: "Revert canceled Estimate quotation",
    endpoint_dependencies: ["revertCanceledEstimateQuotationImpl"],
    permission_dependencies: [PERMISSION.WRITE_STATUS_ESTIMATE_FOR_QUOTATION],
  },
  [PERMISSION.WRITE_ESTIMATE_QUOTATION_APPROVAL]: {
    endpoint_dependencies: ["saveEstimateQuotationApprovalImpl"],
    permission_dependencies: [PERMISSION.READ_ESTIMATE_FOR_QUOTATION],
    name: "Write and modify estimates quotation approval",
  },
  [PERMISSION.WRITE_ESTIMATE_QUOTATION_MARGIN]: {
    endpoint_dependencies: ["updateEstimateQuotationApprovedMarginImpl"],
    permission_dependencies: [PERMISSION.READ_ESTIMATE_FOR_QUOTATION],
    name: "Write and modify estimates quotation approved margin",
  },
  [PERMISSION.WRITE_STATUS_ESTIMATE_FOR_QUOTATION]: {
    endpoint_dependencies: ["updateStatusEstimateQuotationImpl"],
    permission_dependencies: [PERMISSION.READ_ESTIMATE_FOR_QUOTATION],
    name: "Update status of estimate for quotation",
  },
  [PERMISSION.READ_SALES_FOR_QUOTATION]: {
    endpoint_dependencies: [
      "allSalesEstimateImpl",
      "getSalesEstimateImpl",
      "allSalesQuotationImpl",
      "getSalesQuotationImpl",
      "getAllMaterialSalesItem",
    ],
    permission_dependencies: [
      PERMISSION.READ_ESTIMATE_FOR_QUOTATION,
      PERMISSION.READ_USER,
      PERMISSION.READ_CUSTOMER,
    ],
    name: "Read sales for quotation",
  },
  [PERMISSION.READ_SALES_FOR_QUOTATION_PAGES]: {
    endpoint_dependencies: [
      "allSalesEstimateImpl",
      "getSalesEstimateImpl",
      "allSalesQuotationImpl",
      "getSalesQuotationImpl",
      "getAllMaterialSalesItem",
      "readSalesQuotationPagesImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_ESTIMATE_FOR_QUOTATION,
      PERMISSION.READ_USER,
      PERMISSION.READ_CUSTOMER,
      PERMISSION.READ_SALES_FOR_QUOTATION,
    ],
    name: "Read sales for quotation pages",
  },
  [PERMISSION.WRITE_SALES_FOR_QUOTATION]: {
    endpoint_dependencies: [
      "saveSalesEstimateImpl",
      "deleteSalesEstimateImpl",
      "saveSalesQuotationImpl",
      "updateSalesQuotationImpl",
      "deleteSalesQuotationImpl",
      "reviseSalesQuotationImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_SALES_FOR_QUOTATION,
      PERMISSION.READ_CUSTOMER,
      PERMISSION.READ_PAYMENT_METHOD,
      PERMISSION.READ_SALES_FOR_QUOTATION_PAGES,
    ],
    name: "Write and modify sales for quotation",
  },
  [PERMISSION.SPLIT_SALES_FOR_QUOTATION]: {
    name: "Split existing sales for quotation",
    endpoint_dependencies: ["splitSalesQuotationImpl"],
    permission_dependencies: [PERMISSION.WRITE_SALES_FOR_QUOTATION],
  },
  [PERMISSION.WRITE_STATUS_SALES_FOR_QUOTATION]: {
    endpoint_dependencies: [
      "updateStatusSalesQuotationImpl",
      "updateSalesWorkOrderImpl",
    ],
    name: "Update status of sales for quotation",
    permission_dependencies: [PERMISSION.READ_SALES_FOR_QUOTATION],
  },
  [PERMISSION.READ_WORK_ORDER]: {
    endpoint_dependencies: [
      "allWorkOrderImpl",
      "getWorkOrderImpl",
      "allSalesQuotationImpl",
      "getSalesQuotationImpl",
      "allRequestForQuotationImpl",
      "getRequestForQuotationImpl",
    ],
    name: "Read work order",
    permission_dependencies: [
      PERMISSION.READ_SALES_FOR_QUOTATION,
      PERMISSION.READ_REQUEST_FOR_QUOTATION,
    ],
  },
  [PERMISSION.READ_WORK_ORDER_PAGES]: {
    endpoint_dependencies: ["readWorkOrderPagesImpl"],
    permission_dependencies: [PERMISSION.READ_WORK_ORDER],
    name: "Read work order pages",
  },
  [PERMISSION.READ_WORK_ORDER_PRICE]: {
    endpoint_dependencies: ["readWorkOrderPriceImpl"],
    name: "Read prices on work order page",
    permission_dependencies: [PERMISSION.READ_WORK_ORDER_PAGES],
  },
  [PERMISSION.WRITE_WORK_ORDER]: {
    endpoint_dependencies: [
      "saveWorkOrderImpl",
      "updateWorkOrderImpl",
      "deleteWorkOrderImpl",
      "updateStatusWorkOrderImpl",
      "updatePostedWorkOrderImpl",
      "closeWorkOrderEstimateWarrantyStatusImpl",
    ],
    name: "Write and modify work order",
    permission_dependencies: [
      PERMISSION.READ_WORK_ORDER,
      PERMISSION.READ_SALES_FOR_QUOTATION,
      PERMISSION.READ_REQUEST_FOR_QUOTATION,
      PERMISSION.READ_WORK_ORDER_PAGES,
    ],
  },
  [PERMISSION.DELETE_WORK_ORDER_ESTIMATE]: {
    name: "Delete Work Order Estimate Item",
    endpoint_dependencies: ["deleteWorkOrderEstimateImpl"],
    permission_dependencies: [PERMISSION.WRITE_WORK_ORDER],
  },
  [PERMISSION.READ_WORK_ORDER_DISTRIBUTION]: {
    name: "Read work order distribution",
    endpoint_dependencies: [
      "allWorkOrderDistributionImpl",
      "getWorkOrderDistributionImpl",
    ],
    permission_dependencies: [PERMISSION.READ_WORK_ORDER],
  },
  [PERMISSION.WRITE_WORK_ORDER_DISTRIBUTION]: {
    name: "Write and modify work order distribution",
    endpoint_dependencies: [
      "saveWorkOrderDistributionImpl",
      "updateWorkOrderDistributionImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_WORK_ORDER_DISTRIBUTION,
      PERMISSION.READ_CUSTOMER,
    ],
  },
  [PERMISSION.PRINT_WO]: {
    endpoint_dependencies: ["printWo"],
    name: "Print sales work order",
    permission_dependencies: [PERMISSION.READ_SALES_FOR_QUOTATION],
  },
  [PERMISSION.READ_EMPLOYEE]: {
    endpoint_dependencies: ["allEmployeeImpl", "allUserImpl", "getUserImpl"],
    name: "Read employees",
  },
  [PERMISSION.READ_USER]: {
    endpoint_dependencies: ["allUserImpl", "getUserImpl"],
    name: "Read users",
  },
  [PERMISSION.WRITE_USER]: {
    endpoint_dependencies: ["saveUserImpl", "updateUserImpl", "deleteUserImpl"],
    permission_dependencies: [PERMISSION.READ_USER],
    name: "Write, modify, and delete users",
  },
  [PERMISSION.READ_MANUFACTURING_PROCESS]: {
    endpoint_dependencies: [
      "allManufacturingProcessImpl",
      "getManufacturingProcessImpl",
    ],
    name: "Read manufacturing process",
  },
  [PERMISSION.WRITE_MANUFACTURING_PROCESS]: {
    endpoint_dependencies: [
      "saveManufacturingProcessImpl",
      "updateManufacturingProcessImpl",
      "deleteManufacturingProcessImpl",
    ],
    permission_dependencies: [PERMISSION.READ_MANUFACTURING_PROCESS],
    name: "Write and modify manufacturing process",
  },
  [PERMISSION.READ_BILL_OF_MATERIAL]: {
    endpoint_dependencies: [
      "allBillOfMaterialImpl",
      "getBillOfMaterialImpl",
      "getListOfBomStatusPost",
    ],
    permission_dependencies: [
      PERMISSION.READ_MATERIAL,
      PERMISSION.READ_CUSTOMER,
      PERMISSION.READ_SALES_FOR_QUOTATION,
      PERMISSION.READ_COMPANY_INFORMATION,
    ],
    name: "Read bills of material",
  },
  [PERMISSION.READ_BILL_OF_MATERIAL_PAGES]: {
    endpoint_dependencies: [
      "readBillOfMaterialPagesImpl",
      "allBillOfMaterialImpl",
      "getBillOfMaterialImpl",
      "getListOfBomStatusPost",
      "allWorkOrderImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_MATERIAL,
      PERMISSION.READ_CUSTOMER,
      PERMISSION.READ_SALES_FOR_QUOTATION,
      PERMISSION.READ_COMPANY_INFORMATION,
    ],
    name: "See bill of material pages",
  },
  [PERMISSION.WRITE_BILL_OF_MATERIAL]: {
    endpoint_dependencies: [
      "saveBillOfMaterialImpl",
      "updateBillOfMaterialImpl",
      "deleteBillOfMaterialImpl",
      "deleteBillOfMaterialRelationImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_BILL_OF_MATERIAL,
      PERMISSION.READ_MATERIAL,
      PERMISSION.READ_BILL_OF_MATERIAL_PAGES,
    ],
    name: "Write and modify bills of material",
  },
  [PERMISSION.WRITE_STATUS_BILL_OF_MATERIAL]: {
    endpoint_dependencies: ["updateStatusBillOfMaterialImpl"],
    name: "Update status of bills of material",
    permission_dependencies: [PERMISSION.READ_BILL_OF_MATERIAL],
  },
  [PERMISSION.REVISE_BILL_OF_MATERIAL]: {
    endpoint_dependencies: ["reviseBillOfMaterialImpl"],
    name: "Repost bill of materials",
    permission_dependencies: [PERMISSION.WRITE_BILL_OF_MATERIAL],
  },
  [PERMISSION.BASIC_AUTH]: {
    endpoint_dependencies: [
      "checkPermission",
      "login",
      "verify",
      "getCompanyInformationImpl",
      "updateSelfImpl",
    ],
    name: "Basic authentication",
    description:
      "Access to authentication which is needed by all users (login and token verification)",
  },

  // Goods movement
  [PERMISSION.READ_GOODS_ISSUE]: {
    endpoint_dependencies: ["getGoodIssueWithBom", "getListOfBomStatusPost"],
    name: "Read goods issue",
    permission_dependencies: [PERMISSION.READ_BILL_OF_MATERIAL],
  },
  [PERMISSION.CREATE_GOODS_ISSUE]: {
    endpoint_dependencies: ["saveGoodIssueImpl", "updateGoodIssueImpl"],
    name: "Post and update goods issue",
    permission_dependencies: [PERMISSION.CREATE_GOODS_MOVEMENT],
  },
  [PERMISSION.READ_GOODS_MOVEMENT]: {
    endpoint_dependencies: [
      "getGoodReceiptWithPo",
      "getListOfBomStatusPost",
      "readGoodsMovementPageImpl",
    ],
    name: "Read goods movement",
    permission_dependencies: [],
  },
  [PERMISSION.CREATE_GOODS_MOVEMENT]: {
    endpoint_dependencies: ["saveGoodReceiptImpl", "updateGoodReceiptImpl"],
    name: "Post and update goods movement",
    permission_dependencies: [PERMISSION.READ_GOODS_MOVEMENT],
  },
  [PERMISSION.DELETE_GOODS_MOVEMENT]: {
    endpoint_dependencies: ["deleteGoodReceiptImpl", "deleteGoodIssueImpl"],
    name: "Delete goods movement",
    permission_dependencies: [PERMISSION.READ_GOODS_MOVEMENT],
  },

  // Project
  [PERMISSION.READ_PROJECTS]: {
    endpoint_dependencies: [
      "getListOfBomStatusPost",
      "relatedDocumentsInAssignMaterial",
      "getAssignMaterialHistory",
    ],
    name: "Read project management",
    permission_dependencies: [PERMISSION.READ_BILL_OF_MATERIAL],
  },
  [PERMISSION.CHANGE_PROJECT_STATUS]: {
    endpoint_dependencies: ["changeProjectStatus"],
    name: "Change project status",
    permission_dependencies: [PERMISSION.READ_PROJECTS],
  },
  [PERMISSION.ASSIGN_MATERIAL_TO_PROJECTS]: {
    endpoint_dependencies: ["saveAssignMaterialImpl"],
    name: "Assign material to projects",
    permission_dependencies: [PERMISSION.READ_PROJECTS],
  },

  // Incoming Material
  [PERMISSION.READ_INCOMING_MATERIAL]: {
    endpoint_dependencies: [
      "allIncomingMaterialQualityReportImpl",
      "getIncomingMaterialQualityReportImpl",
    ],
    name: "Read incoming material",
    permission_dependencies: [PERMISSION.READ_USER],
  },
  [PERMISSION.WRITE_INCOMING_MATERIAL]: {
    endpoint_dependencies: ["saveIncomingMaterialQualityReportImpl"],
    name: "Create incoming material",
    permission_dependencies: [
      PERMISSION.READ_USER,
      PERMISSION.READ_INCOMING_MATERIAL,
    ],
  },
  [PERMISSION.APPROVE_INCOMING_MATERIAL]: {
    endpoint_dependencies: ["approveIncomingMaterialQualityReportImpl"],
    name: "Approve incoming material",
    permission_dependencies: [PERMISSION.READ_INCOMING_MATERIAL],
  },

  // Outgoing material
  [PERMISSION.READ_OUTGOING_MATERIAL]: {
    endpoint_dependencies: [
      "allOutgoingMaterialQualityReportImpl",
      "getOutgoingMaterialQualityReportImpl",
    ],
    name: "Read outgoing material",
    permission_dependencies: [
      PERMISSION.READ_USER,
      PERMISSION.READ_BILL_OF_MATERIAL,
    ],
  },
  [PERMISSION.WRITE_OUTGOING_MATERIAL]: {
    endpoint_dependencies: [
      "saveOutgoingMaterialQualityReportImpl",
      "updateOutgoingMaterialQualityReportImpl",
    ],
    name: "Create and edit outgoing material",
    permission_dependencies: [PERMISSION.READ_OUTGOING_MATERIAL],
  },
  [PERMISSION.READ_MATERIAL_RETURN_SLIP]: {
    endpoint_dependencies: [
      "allMaterialReturnSlipImpl",
      "getMaterialReturnSlipImpl",
    ],
    name: "Read material return slip",
    permission_dependencies: [PERMISSION.READ_GOODS_ISSUE],
  },
  [PERMISSION.WRITE_MATERIAL_RETURN_SLIP]: {
    endpoint_dependencies: [
      "saveMaterialReturnSlipImpl",
      "updateMaterialReturnSlipImpl",
    ],
    name: "Write and modify material return slip",
    permission_dependencies: [PERMISSION.READ_MATERIAL_RETURN_SLIP],
  },

  // Work time report
  [PERMISSION.READ_DAILY_REPORT]: {
    endpoint_dependencies: ["allDailyReportImpl", "getDailyReportImpl"],
    permission_dependencies: [
      PERMISSION.READ_PROCESS,
      PERMISSION.READ_MACHINE,
      PERMISSION.READ_USER,
      PERMISSION.READ_BILL_OF_MATERIAL,
    ],
    name: "Read daily production report",
  },
  [PERMISSION.READ_OPERATOR_DAILY_REPORT]: {
    endpoint_dependencies: ["allOperatorWorkingHourImpl"],
    permission_dependencies: [PERMISSION.READ_DAILY_REPORT],
    name: "Read operator daily report when create auto machine report",
  },
  [PERMISSION.CHECK_DAILY_REPORT]: {
    endpoint_dependencies: ["checkDailyReportImpl"],
    permission_dependencies: [PERMISSION.READ_DAILY_REPORT],
    name: "Check daily production report",
  },
  [PERMISSION.WRITE_DAILY_REPORT]: {
    endpoint_dependencies: [
      "saveDailyReportImpl",
      "updateDailyReportImpl",
      "deleteDailyReportImpl",
    ],
    permission_dependencies: [PERMISSION.READ_DAILY_REPORT],
    name: "Create & edit daily production report",
  },
  [PERMISSION.READ_WEEKLY_REPORT]: {
    endpoint_dependencies: [
      "allWorkTimeReportImpl",
      "getWorkTimeReportImpl",
      "getWorkTimeReportFromMonthYearImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_PROCESS,
      PERMISSION.READ_MACHINE,
      PERMISSION.READ_DAILY_REPORT,
    ],
    name: "Read weekly work time report",
  },
  [PERMISSION.WRITE_WEEKLY_REPORT]: {
    endpoint_dependencies: [
      "saveWorkTimeReportImpl",
      "updateWorkTimeReportImpl",
      "deleteWorkTimeReportImpl",
    ],
    permission_dependencies: [PERMISSION.READ_WEEKLY_REPORT],
    name: "Create & edit weekly work time report",
  },

  [PERMISSION.READ_MONTHLY_REPORT]: {
    endpoint_dependencies: [
      "allMonthlyWorkTimeReportImpl",
      "getMonthlyWtrDetail",
      "allMonthlyWtrFromYear",
    ],
    permission_dependencies: [
      PERMISSION.READ_WEEKLY_REPORT,
      PERMISSION.READ_ROLES,
      PERMISSION.READ_PROCESS,
      PERMISSION.READ_MACHINE,
    ],
    name: "Read monthly work time report",
  },
  [PERMISSION.WRITE_MONTHLY_REPORT]: {
    endpoint_dependencies: ["saveMonthlyWorkTimeReportImpl"],
    permission_dependencies: [PERMISSION.READ_MONTHLY_REPORT],
    name: "Create & edit monthly work time report",
  },

  [PERMISSION.READ_ANNUAL_REPORT]: {
    endpoint_dependencies: [
      "allAnnualWorkTimeReportImpl",
      "getAnnualWorkTimeReportImpl",
    ],
    permission_dependencies: [PERMISSION.READ_MONTHLY_REPORT],
    name: "Read annual work time report",
  },
  [PERMISSION.WRITE_ANNUAL_REPORT]: {
    endpoint_dependencies: ["saveAnnualWorkTimeReportImpl"],
    permission_dependencies: [PERMISSION.READ_ANNUAL_REPORT],
    name: "Create & edit annual work time report",
  },

  [PERMISSION.READ_PURCHASE_REQUISITION]: {
    endpoint_dependencies: [
      "allPurchaseRequisitionImpl",
      "getPurchaseRequisitionImpl",
    ],
    name: "Read purchase requisition",
    permission_dependencies: [PERMISSION.READ_MATERIAL],
  },
  [PERMISSION.WRITE_STATUS_PURCHASE_REQUISITION]: {
    endpoint_dependencies: ["updateStatusPurchaseRequisition"],
    name: "Update status purchase requisition",
    permission_dependencies: [PERMISSION.READ_PURCHASE_REQUISITION],
  },
  [PERMISSION.CREATE_PURCHASE_REQUISITION]: {
    endpoint_dependencies: [
      "savePurchaseRequisitionImpl",
      "updatePurchaseRequisitionImpl",
    ],
    name: "Create & edit requisition",
    permission_dependencies: [PERMISSION.READ_PURCHASE_REQUISITION],
  },
  [PERMISSION.APPROVE_PURCHASE_REQUISITION]: {
    endpoint_dependencies: [
      "allProductDescriptionImpl",
      "rejectProductDescription",
      "updateProductDescriptionApprovalStatusImpl",
    ],
    name: "Approve & reject requisition",
    permission_dependencies: [PERMISSION.READ_PURCHASE_REQUISITION],
  },
  [PERMISSION.MERGE_PURCHASE_REQUISITION]: {
    endpoint_dependencies: ["mergePurchaseRequisitionImpl"],
    name: "Merge purchase requisition",
    permission_dependencies: [PERMISSION.CREATE_PURCHASE_REQUISITION],
  },
  // Stock Adjustment
  [PERMISSION.READ_STOCK_ADJUSTMENT]: {
    endpoint_dependencies: [
      "allStockAdjustmentImpl",
      "getStockAdjustmentImpl",
      "allStockAdjustmentMaterialImpl",
    ],
    permission_dependencies: [PERMISSION.READ_MATERIAL],
    name: "Read stock adjustment",
  },
  [PERMISSION.CREATE_STOCK_ADJUSTMENT]: {
    endpoint_dependencies: [
      "saveStockAdjustmentImpl",
      "exportTemplateStockAdjustmentImpl",
      "importExcelStockAdjustmentImpl",
    ],
    name: "Create Stock Adjustment",
    permission_dependencies: [
      PERMISSION.READ_MATERIAL,
      PERMISSION.READ_STOCK_ADJUSTMENT,
    ],
  },
  [PERMISSION.READ_DELIVERY_NOTES]: {
    endpoint_dependencies: ["allDeliveryNoteImpl", "getDeliveryNoteImpl"],
    permission_dependencies: [
      PERMISSION.READ_COMPANY_INFORMATION,
      PERMISSION.READ_CUSTOMER,
      PERMISSION.READ_USER,
      PERMISSION.READ_SALES_FOR_QUOTATION,
    ],
    name: "Read delivery notes",
  },
  [PERMISSION.WRITE_DELIVERY_NOTES]: {
    endpoint_dependencies: ["saveDeliveryNoteImpl", "updateDeliveryNoteImpl"],
    permission_dependencies: [
      PERMISSION.READ_DELIVERY_NOTES,
      PERMISSION.READ_CUSTOMER,
      PERMISSION.READ_USER,
      PERMISSION.READ_SALES_FOR_QUOTATION,
    ],
    name: "Write and modify delivery notes",
  },
  [PERMISSION.READ_DELIVERY_NOTES_SUPPLIER]: {
    endpoint_dependencies: [
      "allDeliveryNoteSupplierImpl",
      "getDeliveryNoteSupplierImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_COMPANY_INFORMATION,
      PERMISSION.READ_VENDOR,
      PERMISSION.READ_USER,
      PERMISSION.READ_PURCHASE_ORDER,
    ],
    name: "Read delivery notes supplier",
  },
  [PERMISSION.WRITE_DELIVERY_NOTES_SUPPLIER]: {
    endpoint_dependencies: [
      "saveDeliveryNoteSupplierImpl",
      "updateDeliveryNoteSupplierImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_MATERIAL,
      PERMISSION.READ_DELIVERY_NOTES_SUPPLIER,
      PERMISSION.READ_BILL_OF_MATERIAL,
    ],
    name: "Write and modify delivery notes supplier",
  },
  [PERMISSION.READ_STOCK_MONITORING]: {
    // TODO: Add!
    endpoint_dependencies: ["allStockMonitoringImpl"],
    permission_dependencies: [
      PERMISSION.READ_MATERIAL,
      PERMISSION.READ_PURCHASE_ORDER,
      PERMISSION.READ_BILL_OF_MATERIAL,
    ],
    name: "Read stock monitoring",
  },
  [PERMISSION.READ_MATERIAL_TRANSACTION]: {
    endpoint_dependencies: [
      "allMaterialTransactionImpl",
      "getPoNumberFromGrNumber",
      "getWoNumberFromGiNumber",
      "getIdStockAdjustmentFromSaNumber",
    ],
    permission_dependencies: [PERMISSION.READ_MATERIAL],
    name: "Read material transaction",
  },
  [PERMISSION.READ_REQUEST_FOR_QUOTATION]: {
    endpoint_dependencies: [
      "allRequestForQuotationImpl",
      "getRequestForQuotationImpl",
    ],
    permission_dependencies: [PERMISSION.READ_CUSTOMER],
    name: "Read request for quotation",
  },
  [PERMISSION.READ_REQUEST_FOR_QUOTATION_PAGES]: {
    endpoint_dependencies: ["readRequestForQuotationPagesImpl"],
    permission_dependencies: [PERMISSION.READ_REQUEST_FOR_QUOTATION],
    name: "Read request for quotation pages",
  },
  [PERMISSION.WRITE_REQUEST_FOR_QUOTATION]: {
    endpoint_dependencies: [
      "updateRequestForQuotation",
      "saveRequestForQuotation",
    ],
    permission_dependencies: [
      PERMISSION.READ_REQUEST_FOR_QUOTATION,
      PERMISSION.READ_CUSTOMER,
      PERMISSION.READ_REQUEST_FOR_QUOTATION_PAGES,
    ],
    name: "Write and modify request for quotation",
  },
  [PERMISSION.READ_MATERIAL_TYPE]: {
    endpoint_dependencies: ["allMaterialTypeImpl", "getMaterialTypeImpl"],
    name: "Read material type",
  },
  [PERMISSION.WRITE_MATERIAL_TYPE]: {
    endpoint_dependencies: [
      "updateMaterialType",
      "saveMaterialType",
      "deleteMaterialType",
    ],
    permission_dependencies: [PERMISSION.READ_MATERIAL_TYPE],
    name: "Write and modify material type",
  },
  [PERMISSION.READ_CUT_OFF]: {
    endpoint_dependencies: ["allCutOffImpl", "getCutOffImpl"],
    permission_dependencies: [PERMISSION.READ_MATERIAL],
    name: "Read cut off",
  },
  [PERMISSION.WRITE_CUT_OFF]: {
    endpoint_dependencies: ["saveCutOff"],
    permission_dependencies: [PERMISSION.READ_CUT_OFF],
    name: "Write cut off",
  },
  [PERMISSION.READ_SECTION]: {
    endpoint_dependencies: ["allSectionImpl", "getSectionImpl"],
    name: "Read section",
  },
  [PERMISSION.READ_SECTION_PAGES]: {
    endpoint_dependencies: ["readSectionPagesImpl"],
    name: "Read section pages",
  },
  [PERMISSION.WRITE_SECTION]: {
    endpoint_dependencies: [
      "saveSectionImpl",
      "updateSectionImpl",
      "deleteSectionImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_SECTION_PAGES,
      PERMISSION.READ_SECTION,
    ],
    name: "Write and modify section",
  },
  [PERMISSION.READ_INVOICE]: {
    endpoint_dependencies: [
      "allInvoiceImpl",
      "getInvoiceImpl",
      "allAuthorizedSignatureImpl",
    ],
    permission_dependencies: [PERMISSION.READ_DELIVERY_NOTES],
    name: "Read invoice",
  },
  [PERMISSION.READ_INVOICE_SUPPLIER]: {
    endpoint_dependencies: ["allInvoiceSupplierImpl", "getInvoiceSupplierImpl"],
    permission_dependencies: [PERMISSION.READ_PURCHASE_ORDER],
    name: "Read invoice supplier",
  },
  [PERMISSION.READ_INVOICE_PAGES]: {
    name: "Read invoice pages",
    endpoint_dependencies: ["readInvoicePagesImpl"],
    permission_dependencies: [PERMISSION.READ_INVOICE],
  },
  [PERMISSION.ENABLE_REVISE_SQ_FROM_INVOICE]: {
    name: "Enable revise Sales Quotation Related to Invoice",
    endpoint_dependencies: ["updateInvoiceEnableReviseStatusImpl"],
    permission_dependencies: [PERMISSION.READ_INVOICE],
  },
  [PERMISSION.WRITE_INVOICE]: {
    endpoint_dependencies: [
      "saveInvoiceImpl",
      "updateInvoiceImpl",
      "saveInvoiceMergeImpl",
      "deleteInvoiceImpl",
      "updateStatusInvoiceImpl",
      "updateInvoiceMergeImpl",
      "deleteInvoiceMergeImpl",
    ],
    permission_dependencies: [
      PERMISSION.READ_INVOICE,
      PERMISSION.READ_INVOICE_PAGES,
    ],
    name: "Write and modify invoice",
  },
  [PERMISSION.WRITE_INVOICE_PAYMENT_STATUS]: {
    endpoint_dependencies: ["updatePaymentStatusInvoiceImpl"],
    name: "Modify invoice payment status",
    permission_dependencies: [PERMISSION.READ_INVOICE_PAGES],
  },
  [PERMISSION.WRITE_INVOICE_SUPPLIER]: {
    endpoint_dependencies: [
      "saveInvoiceSupplierImpl",
      "updateInvoiceSupplierImpl",
    ],
    permission_dependencies: [PERMISSION.READ_INVOICE_SUPPLIER],
    name: "Write and modify invoice supplier",
  },
  [PERMISSION.WRITE_INVOICE_SUPPLIER_STATUS]: {
    endpoint_dependencies: ["updateStatusInvoiceSupplierImpl"],
    permission_dependencies: [PERMISSION.READ_INVOICE_SUPPLIER],
    name: "Write and modify invoice supplier status",
  },
  [PERMISSION.READ_ACCOUNT_PAYABLE_DASHBOARD]: {
    endpoint_dependencies: ["allAccountPayableImpl"],
    name: "Account Payable (AP)",
  },
  [PERMISSION.EXPORT_ACCOUNT_PAYABLE_DASHBOARD]: {
    endpoint_dependencies: ["exportAccountPayableReport"],
    name: "Export Account Payable (AP)",
    permission_dependencies: [PERMISSION.READ_ACCOUNT_PAYABLE_DASHBOARD],
  },
  [PERMISSION.READ_ACCOUNT_RECEIVABLE_DASHBOARD]: {
    endpoint_dependencies: ["allAccountReceivableImpl"],
    name: "Read Account Receivable",
  },
  [PERMISSION.EXPORT_ACCOUNT_RECEIVABLE_DASHBOARD]: {
    endpoint_dependencies: ["exportAccountReceivableReport"],
    permission_dependencies: [PERMISSION.READ_ACCOUNT_RECEIVABLE_DASHBOARD],
    name: "Export Account Receivable (AR)",
  },
  [PERMISSION.READ_WTR_MONTHLY_DASHBOARD]: {
    endpoint_dependencies: ["allWorkingTimeReportImpl"],
    name: "Read Working Time Report (Monthly)",
  },
  [PERMISSION.EXPORT_WTR_MONTHLY_DASHBOARD]: {
    endpoint_dependencies: ["exportWorkingTimeReport"],
    permission_dependencies: [PERMISSION.READ_WTR_MONTHLY_DASHBOARD],
    name: "Export Working Time Report (Monthly)",
  },
  [PERMISSION.READ_WTR_OPERATOR_DASHBOARD]: {
    endpoint_dependencies: ["allDailyWorkTimeImpl"],
    name: "Read Working Time Report (Operator)",
  },
  [PERMISSION.EXPORT_WTR_OPERATOR_DASHBOARD]: {
    endpoint_dependencies: ["exportOperatorWorkingTimeReport"],
    permission_dependencies: [PERMISSION.READ_WTR_OPERATOR_DASHBOARD],
    name: "Export Working Time Report (Operator)",
  },
  [PERMISSION.READ_WTR_MACHINE_DASHBOARD]: {
    endpoint_dependencies: [
      "allDailyWorkTimeImpl",
      "readWtrMachineDashboardImpl",
    ],
    name: "Read Working Time Report (Machine)",
  },
  [PERMISSION.EXPORT_WTR_MACHINE_DASHBOARD]: {
    endpoint_dependencies: [
      "exportOperatorWorkingTimeReport",
      "exportWtrMachineDashboardImpl",
      "exportMachineWorkingTimeReport",
    ],
    permission_dependencies: [PERMISSION.READ_WTR_MACHINE_DASHBOARD],
    name: "Export Working Time Report (Machine)",
  },
  [PERMISSION.MODIFY_WTR_OPERATOR_DASHBOARD]: {
    endpoint_dependencies: ["modifyOperatorWorkingTimeReport"],
    permission_dependencies: [PERMISSION.READ_WTR_OPERATOR_DASHBOARD],
    name: "Modify Working Time Report (Operator)",
  },
  [PERMISSION.READ_PURCHASE_ORDER_SUMMARY_DASHBOARD]: {
    endpoint_dependencies: [
      "getPurchaseOrderSummaryImpl",
      "getActualCostSummaryImpl",
    ],
    name: "Read Purchase Order Summary",
  },
  [PERMISSION.READ_PROJECT_LIST_DASHBOARD]: {
    endpoint_dependencies: ["allVProjectListImpl", "readProjectListPageImpl"],
    name: "Read Project List Dashboard",
  },
  [PERMISSION.READ_BILL_OF_MATERIAL_COST_DASHBOARD]: {
    endpoint_dependencies: [
      "allSummaryBillOfMaterialCostImpl",
      "readBillOfMaterialCostPageImpl",
    ],
    name: "Read Bill Of Material Cost",
  },
  [PERMISSION.READ_PROCESS_COST_DASHBOARD]: {
    endpoint_dependencies: [
      "allSummaryProcessCostImpl",
      "readProcessCostPageImpl",
    ],
    name: "Read Process Cost",
  },
  [PERMISSION.READ_PROJECT_PROFIT_DASHBOARD]: {
    endpoint_dependencies: [
      "readProjectProfitPageImpl",
      "allProjectCostDifferenceImpl",
      "getProjectCostDifferenceImpl",
    ],
    name: "Read Project Profit",
  },
  [PERMISSION.READ_PROJECT_HOURS_DASHBOARD]: {
    endpoint_dependencies: [
      "allProjectHourDifferenceImpl",
      "getProjectHourDifferenceImpl",
      "readProjectHourPageImpl",
    ],
    name: "Read Project Hour",
  },
  [PERMISSION.READ_WORK_ORDER_DELIVERY_SCHEDULE_DASHBOARD]: {
    endpoint_dependencies: ["allWorkOrderDeliveryScheduleImpl"],
    name: "Read Work Order Delivery Schedule",
  },
  [PERMISSION.READ_PURCHASING_REPORT_DASHBOARD]: {
    endpoint_dependencies: [
      "readPurchasingReportDashboardImpl",
      "allOrderedProductImpl",
    ],
    name: "Read Purchasing Report Dashboard",
  },
  [PERMISSION.READ_MOULD_CODE_REPORT_DASHBOARD]: {
    endpoint_dependencies: [
      "allRequestForQuotationImpl",
      "readMouldCodeReportDashboardImpl",
    ],
    name: "Read Mould Code Report Dashboard",
  },
  [PERMISSION.READ_SALES_QUOTATION_OUTSTANDING_DASHBOARD]: {
    endpoint_dependencies: [
      "allSalesQuotationImpl",
      "readSalesQuotationOutstandingDashboardImpl",
    ],
    name: "Read Sales Quotation Outstanding Report Dashboard",
  },
  [PERMISSION.READ_MARKETING_PROJECT_REPORT_DASHBOARD]: {
    endpoint_dependencies: ["allMarketingProjectImpl"],
    name: "Read Marketing Project Report",
  },
  [PERMISSION.READ_ACTIVITY_LOG_DASHBOARD]: {
    endpoint_dependencies: [
      "allUserActivityLogImpl",
      "readUserActivityLogDashboardImpl",
    ],
    name: "Read User Activity Log Dashboard",
  },
  [PERMISSION.READ_PO_CUSTOMER_DASHBOARD]: {
    endpoint_dependencies: ["allPoCustomerImpl"],
    name: "Read PO. Customer Dashboard",
  },
  [PERMISSION.READ_PURCHASE_ORDER_DELIVERY_SCHEDULE_DASHBOARD]: {
    endpoint_dependencies: ["allPurchaseOrderDeliveryScheduleImpl"],
    name: "Read Purchase Order Delivery Schedule",
  },
  [PERMISSION.READ_PURCHASING_USAGE_REPORT]: {
    endpoint_dependencies: ["getPurchasingUsageImpl"],
    name: "Read Purchasing Usage Report",
  },
  [PERMISSION.READ_COVER_WORKING_TIME_DASHBOARD]: {
    endpoint_dependencies: [
      "allDailyWorkTimeImpl",
      "allCoverWorkingTimeDashboard",
    ],
    name: "Read Cover Working Time Dashboard",
  },
  [PERMISSION.EXPORT_COVER_WORKING_TIME_DASHBOARD]: {
    endpoint_dependencies: ["exportCoverWokingTimeDashboard"],
    name: "Export Cover Working Time Dashboard",
    permission_dependencies: [PERMISSION.READ_COVER_WORKING_TIME_DASHBOARD],
  },
  [PERMISSION.READ_GOODS_RECEIPT_TRACKING_REPORT]: {
    endpoint_dependencies: [
      "allPurchaseOrderImpl",
      "readGoodsReceiptTrackingReport",
    ],
    name: "Read Goods Receipt Tracking Report",
  },
  [PERMISSION.READ_PURCHASING_BUYING_DASHBOARD]: {
    endpoint_dependencies: [
      "allPurchasingBuyingImpl",
      "getTotalVendorPurchaseOrderImpl",
      "getTotalAmountPurchaseOrderImpl",
    ],
    name: "Read Purchasing Buying Dashboard",
  },
  [PERMISSION.READ_WORK_ORDER_RECENT_DASHBOARD]: {
    permission_dependencies: [PERMISSION.READ_WORK_ORDER],
    endpoint_dependencies: ["readWorkOrderRecentImpl"],
    name: "Read Work Order Recent",
  },
  [PERMISSION.READ_MANUFACTURING_PROGRESS]: {
    endpoint_dependencies: [
      "allManufacturingProgressImpl",
      "allBillOfMaterialImpl",
      "getManufacturingProgressImpl",
    ],
    name: "Read Manufacturing Progress",
  },
  [PERMISSION.WRITE_MANUFACTURING_PROGRESS]: {
    endpoint_dependencies: [
      "saveManufacturingProgressImpl",
      "updateManufacturingProgressImpl",
      "deleteManufacturingProgressImpl",
    ],
    permission_dependencies: [PERMISSION.READ_MANUFACTURING_PROGRESS],
    name: "Write and Modify Manufacturing Progress",
  },
  [PERMISSION.READ_SALES_SUMMARY_DASHBOARD]: {
    endpoint_dependencies: ["getSalesSummaryImpl", "allInvoiceSalesImpl"],
    name: "Read Sales Summary Dashboard",
  },
  [PERMISSION.READ_MANUFACTURING_PROGRESS_REPORT]: {
    permission_dependencies: [PERMISSION.READ_MANUFACTURING_PROGRESS],
    endpoint_dependencies: ["readManufacturingProgressReport"],
    name: "Read Manufacturing Progress Report",
  },
  [PERMISSION.READ_MANUFACTURING_PLANNING]: {
    permission_dependencies: [
      PERMISSION.READ_CUSTOMER,
      PERMISSION.READ_BILL_OF_MATERIAL,
    ],
    endpoint_dependencies: [
      "allManufacturingPlanningImpl",
      "getManufacturingPlanningImpl",
    ],
    name: "Read Manufacturing Planning",
  },
  [PERMISSION.WRITE_MANUFACTURING_PLANNING]: {
    permission_dependencies: [PERMISSION.READ_MANUFACTURING_PLANNING],
    endpoint_dependencies: [
      "saveManufacturingPlanningImpl",
      "updateManufacturingPlanningImpl",
      "reviseManufacturingPlanningImpl",
      "updateManufacturingPlanningItemImpl",
    ],
    name: "Write and modify Manufacturing Planning",
  },
  [PERMISSION.READ_MANUFACTURING_PLANNING_REPORT]: {
    permission_dependencies: [PERMISSION.READ_MANUFACTURING_PLANNING],
    endpoint_dependencies: ["readManufacturingPlanningReportImpl"],
    name: "Read Manufacturing Planning Report",
  },
  [PERMISSION.READ_COMPILATION_OF_PROJECT_PROFIT]: {
    permission_dependencies: [PERMISSION.READ_PROJECT_PROFIT_DASHBOARD],
    endpoint_dependencies: ["readCompilationProjectProfitImpl"],
    name: "Read Compilation of Project Profit",
  },
  [PERMISSION.READ_MOULD_CODE_YEAR]: {
    endpoint_dependencies: ["allMouldCodeYearImpl", "getMouldCodeYearImpl"],
    name: "Read Mould Code Year",
  },
  [PERMISSION.WRITE_MOULD_CODE_YEAR]: {
    permission_dependencies: [PERMISSION.READ_MOULD_CODE_YEAR],
    endpoint_dependencies: [
      "saveMouldCodeYearImpl",
      "updateMouldCodeYearImpl",
      "deleteMouldCodeYearImpl",
    ],
    name: "Write and modify mould code year",
  },
  [PERMISSION.READ_MOULD_CODE]: {
    permission_dependencies: [PERMISSION.READ_CUSTOMER],
    endpoint_dependencies: ["allMouldCodeImpl", "getMouldCodeImpl"],
    name: "Read Mould Code",
  },
  [PERMISSION.WRITE_MOULD_CODE]: {
    permission_dependencies: [PERMISSION.READ_MOULD_CODE],
    endpoint_dependencies: [
      "saveMouldCodeImpl",
      "updateMouldCodeImpl",
      "deleteMouldCodeImpl",
    ],
    name: "Write and modify mould code list",
  },
}

export default PERMISSIONS
