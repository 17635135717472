let counter = 0

/**
 * Get counter number and increment it
 */
const inc = () => {
  counter += 1
  return counter - 1
}

const PERMISSION_CODES = {
  READ_MATERIAL: inc(),
  WRITE_MATERIAL: inc(),
  READ_PAYMENT_METHOD: inc(),
  WRITE_PAYMENT_METHOD: inc(),
  READ_CURRENCY: inc(),
  WRITE_CURRENCY: inc(),
  READ_CUSTOMER: inc(),
  WRITE_CUSTOMER: inc(),
  READ_COMPANY_INFORMATION: inc(),
  WRITE_COMPANY_INFORMATION: inc(),
  READ_PROCESS: inc(),
  WRITE_PROCESS: inc(),
  READ_MACHINE: inc(),
  WRITE_MACHINE: inc(),
  READ_PURCHASE_ORDER: inc(),
  WRITE_PURCHASE_ORDER: inc(),
  WRITE_STATUS_PURCHASE_ORDER: inc(),
  SET_RECEIVED_PURCHASE_ORDER: inc(),
  WRITE_AMOUNT_PURCHASE_ORDER: inc(),
  READ_VENDOR: inc(),
  WRITE_VENDOR: inc(),
  READ_ROLES: inc(),
  WRITE_ROLES: inc(),
  READ_USER: inc(),
  WRITE_USER: inc(),
  READ_MANUFACTURING_PROCESS: inc(),
  WRITE_MANUFACTURING_PROCESS: inc(),

  // Quotations
  READ_ESTIMATE_FOR_QUOTATION: inc(),
  REVERT_CANCELED_ESTIMATE_FOR_QUOTATION: inc(),
  WRITE_ESTIMATE_FOR_QUOTATION: inc(),
  WRITE_START_DATE_PROJECT_ESTIMATE_FOR_QUOTATION: inc(),
  WRITE_STATUS_ESTIMATE_FOR_QUOTATION: inc(),
  READ_SALES_FOR_QUOTATION: inc(),
  WRITE_SALES_FOR_QUOTATION: inc(),
  WRITE_STATUS_SALES_FOR_QUOTATION: inc(),
  SPLIT_SALES_FOR_QUOTATION: inc(),
  PRINT_WO: inc(),

  // BOM
  READ_BILL_OF_MATERIAL: inc(),
  WRITE_BILL_OF_MATERIAL: inc(),
  WRITE_STATUS_BILL_OF_MATERIAL: inc(),
  REVISE_BILL_OF_MATERIAL: inc(),

  // Goods issue and goods movement
  CREATE_GOODS_ISSUE: inc(),
  READ_GOODS_ISSUE: inc(),
  CREATE_GOODS_MOVEMENT: inc(),
  READ_GOODS_MOVEMENT: inc(),
  DELETE_GOODS_MOVEMENT: inc(),

  // Incoming Material
  READ_INCOMING_MATERIAL: inc(),
  WRITE_INCOMING_MATERIAL: inc(),
  APPROVE_INCOMING_MATERIAL: inc(),

  // Outgoing material
  READ_OUTGOING_MATERIAL: inc(),
  WRITE_OUTGOING_MATERIAL: inc(),

  // Return slip
  READ_MATERIAL_RETURN_SLIP: inc(),
  WRITE_MATERIAL_RETURN_SLIP: inc(),

  // Project management
  READ_PROJECTS: inc(),
  ASSIGN_MATERIAL_TO_PROJECTS: inc(),
  CHANGE_PROJECT_STATUS: inc(),

  BASIC_AUTH: inc(),

  // Work time report
  READ_DAILY_REPORT: inc(),
  WRITE_DAILY_REPORT: inc(),
  CHECK_DAILY_REPORT: inc(),
  READ_WEEKLY_REPORT: inc(),
  WRITE_WEEKLY_REPORT: inc(),
  READ_MONTHLY_REPORT: inc(),
  WRITE_MONTHLY_REPORT: inc(),
  READ_ANNUAL_REPORT: inc(),
  WRITE_ANNUAL_REPORT: inc(),
  READ_OPERATOR_DAILY_REPORT: inc(),

  // Purchase requisition
  READ_PURCHASE_REQUISITION: inc(),
  CREATE_PURCHASE_REQUISITION: inc(),
  APPROVE_PURCHASE_REQUISITION: inc(),
  WRITE_STATUS_PURCHASE_REQUISITION: inc(),
  MERGE_PURCHASE_REQUISITION: inc(),

  // Stock Adjustment
  READ_STOCK_ADJUSTMENT: inc(),
  CREATE_STOCK_ADJUSTMENT: inc(),

  // Delivery Notes
  READ_DELIVERY_NOTES: inc(),
  WRITE_DELIVERY_NOTES: inc(),
  READ_DELIVERY_NOTES_SUPPLIER: inc(),
  WRITE_DELIVERY_NOTES_SUPPLIER: inc(),

  // Material transaction and report stock monitoring
  READ_MATERIAL_TRANSACTION: inc(),

  // Request For Quotation
  READ_REQUEST_FOR_QUOTATION: inc(),
  WRITE_REQUEST_FOR_QUOTATION: inc(),

  READ_STOCK_MONITORING: inc(),

  // Work Order
  READ_WORK_ORDER: inc(),
  READ_WORK_ORDER_PRICE: inc(),
  WRITE_WORK_ORDER: inc(),
  DELETE_WORK_ORDER_ESTIMATE: inc(),
  READ_WORK_ORDER_DISTRIBUTION: inc(),
  WRITE_WORK_ORDER_DISTRIBUTION: inc(),

  // Material Type
  READ_MATERIAL_TYPE: inc(),
  WRITE_MATERIAL_TYPE: inc(),

  // Cut Off
  READ_CUT_OFF: inc(),
  WRITE_CUT_OFF: inc(),

  // Employee
  READ_EMPLOYEE: inc(),

  // Read Pages
  READ_PROCESS_PAGES: inc(),
  READ_MACHINE_PAGES: inc(),
  READ_BILL_OF_MATERIAL_PAGES: inc(),
  READ_COMPANY_INFORMATION_PAGES: inc(),
  READ_CUSTOMER_PAGES: inc(),
  READ_CURRENCY_PAGES: inc(),
  READ_PAYMENT_METHOD_PAGES: inc(),
  READ_MATERIAL_PAGES: inc(),
  READ_ESTIMATE_FOR_QUOTATION_PAGES: inc(),
  READ_SALES_FOR_QUOTATION_PAGES: inc(),
  READ_ROLE_PAGES: inc(),
  READ_VENDOR_PAGES: inc(),
  READ_REQUEST_FOR_QUOTATION_PAGES: inc(),
  READ_WORK_ORDER_PAGES: inc(),
  READ_INVOICE_PAGES: inc(),
  READ_PURCHASE_ORDER_PAGES: inc(),

  // Section
  READ_SECTION: inc(),
  READ_SECTION_PAGES: inc(),
  WRITE_SECTION: inc(),

  // Invoice
  READ_INVOICE: inc(),
  WRITE_INVOICE: inc(),
  WRITE_INVOICE_PAYMENT_STATUS: inc(),
  ENABLE_REVISE_SQ_FROM_INVOICE: inc(),

  READ_INVOICE_SUPPLIER: inc(),
  WRITE_INVOICE_SUPPLIER: inc(),
  WRITE_INVOICE_SUPPLIER_STATUS: inc(),

  WRITE_ESTIMATE_QUOTATION_APPROVAL: inc(),
  WRITE_ESTIMATE_QUOTATION_MARGIN: inc(),
  READ_MANUFACTURING_PLANNING: inc(),
  WRITE_MANUFACTURING_PLANNING: inc(),

  READ_MANUFACTURING_PROGRESS: inc(),
  WRITE_MANUFACTURING_PROGRESS: inc(),

  // Mould Code
  READ_MOULD_CODE_YEAR: inc(),
  WRITE_MOULD_CODE_YEAR: inc(),
  READ_MOULD_CODE: inc(),
  WRITE_MOULD_CODE: inc(),

  // Dashboard
  READ_ACCOUNT_PAYABLE_DASHBOARD: inc(),
  EXPORT_ACCOUNT_PAYABLE_DASHBOARD: inc(),
  READ_ACCOUNT_RECEIVABLE_DASHBOARD: inc(),
  EXPORT_ACCOUNT_RECEIVABLE_DASHBOARD: inc(),
  READ_WTR_MONTHLY_DASHBOARD: inc(),
  EXPORT_WTR_MONTHLY_DASHBOARD: inc(),
  READ_WTR_OPERATOR_DASHBOARD: inc(),
  MODIFY_WTR_OPERATOR_DASHBOARD: inc(),
  EXPORT_WTR_OPERATOR_DASHBOARD: inc(),
  READ_PURCHASE_ORDER_SUMMARY_DASHBOARD: inc(),
  READ_PROJECT_LIST_DASHBOARD: inc(),
  READ_BILL_OF_MATERIAL_COST_DASHBOARD: inc(),
  READ_PROCESS_COST_DASHBOARD: inc(),
  READ_PROJECT_PROFIT_DASHBOARD: inc(),
  READ_COMPILATION_OF_PROJECT_PROFIT: inc(),
  READ_PROJECT_HOURS_DASHBOARD: inc(),
  READ_WORK_ORDER_DELIVERY_SCHEDULE_DASHBOARD: inc(),
  READ_PURCHASING_REPORT_DASHBOARD: inc(),
  READ_MOULD_CODE_REPORT_DASHBOARD: inc(),
  READ_SALES_QUOTATION_OUTSTANDING_DASHBOARD: inc(),
  READ_MARKETING_PROJECT_REPORT_DASHBOARD: inc(),
  READ_ACTIVITY_LOG_DASHBOARD: inc(),
  READ_WTR_MACHINE_DASHBOARD: inc(),
  EXPORT_WTR_MACHINE_DASHBOARD: inc(),
  READ_PO_CUSTOMER_DASHBOARD: inc(),
  READ_PURCHASE_ORDER_DELIVERY_SCHEDULE_DASHBOARD: inc(),
  READ_PURCHASING_USAGE_REPORT: inc(),
  READ_COVER_WORKING_TIME_DASHBOARD: inc(),
  EXPORT_COVER_WORKING_TIME_DASHBOARD: inc(),
  READ_GOODS_RECEIPT_TRACKING_REPORT: inc(),
  READ_PURCHASING_BUYING_DASHBOARD: inc(),
  READ_WORK_ORDER_RECENT_DASHBOARD: inc(),
  READ_SALES_SUMMARY_DASHBOARD: inc(),
  READ_MANUFACTURING_PROGRESS_REPORT: inc(),
  READ_MANUFACTURING_PLANNING_REPORT: inc(),
}

export default PERMISSION_CODES
